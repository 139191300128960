.footer{
    .top{
        background:url(../../../img/footer.svg) #F67E7E;
        background-repeat: no-repeat;
        background-position: left bottom;
        height: 200px;
        display: flex;
        align-items: center; 
        .container{
            display: flex;
            align-items: center;
            .title{
                margin-left: 50px;
                margin-right: 350px;
                color:#012F34;
                font-weight: 700;
                font-size: 48px;
                line-height: 48px;
            }
            .contact{
                border: 2px solid #012F34;
                width: 153px;
                height: 48px;
                color: #012F34;
                text-align: center;
                border-radius: 40px;
                padding-top: 8px;
                font-weight: 600;
                font-size: 18px;
                line-height: 28px;
            }
        }
    }
    .bottom{
        background: #002529;
        height: 196px;
        .container{
            padding-top: 50px;
            display: flex;
            justify-content: space-between;
            .nav{
                width: 160px;
                .list{
                    padding-left: 0;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    &__item{
                        margin-top: 25px;
                        a{
                            color: #fff;
                        }
                    }
                }
            }
            .company__info{
                .list{
                    &__item{
                        color: #fff;
                        a{
                            color: #fff;
                        }
                    }
                }
            }
            .company__icons{
                .list{
                    margin-left: 130px;
                    display: flex;
                    align-items: center;
                    &__item{
                        margin-right: 20px;
                        a{
                            color: #fff;
                        }
                    }
                }
                p{
                    margin-top: 60px;
                    color: #fff;
                }
            }
        }
    }
}