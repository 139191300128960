.header{
    background: #014E56;
    position: fixed;
    width: 100%;
    height: 120px;
    .nav{
        margin-top: 30px;
        display: flex;
        align-items: center;
        &__list{
            display: flex;
            align-items: center;
            &__item{
                margin-left: 80px;
                a{
                    color: #fff;
                    font-size: 18px;
                }
            }
            &__contact{
                margin-left: 570px;
                width: 153px;
                height: 48px;
                border-radius: 1.5rem;
                border: 2px solid #fff;
                color: #fff;
                background: none;
                font-size: 18px;
                text-align: center;
                padding-top: 8px;
            }
        }
    }
}

li{
    list-style-type: none;
}

a{
    text-decoration: none;
}