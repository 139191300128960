@media (max-width: 768px) {
    .footer{
        .top{
            height: 200px;
            max-width: 768px;
            display: flex;
            align-items: center; 
            .container{
                display: flex;
                align-items: center;
                .title{
                    margin-left: 100px;
                    margin-right: 90px;
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 32px;
                }
            }
        }
        .bottom{
            max-width: 768px;
            .container{
                padding-top: 56px;
                display: flex;
                justify-content: space-between;
                .nav{
                    width: 160px;
                    .list{
                        padding-left: 0;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        &__item{
                            margin-top: 25px;
                        }
                    }
                }
                .company__info{
                    .list{
                        &__item{
                            color: #fff;
                            a{
                                color: #fff;
                            }
                        }
                    }
                }
                .company__icons{
                    .list{
                        margin-left: 130px;
                        display: flex;
                        align-items: center;
                        &__item{
                            margin-right: 20px;
                            a{
                                color: #fff;
                            }
                        }
                    }
                    p{
                        margin-top: 60px;
                        color: #fff;
                    }
                }
            }
        }
    }
}