@media (max-width: 768px) {
    .header{
        max-width: 768px;
        .container{ 
            .nav{
                .nav__img{
                    margin-left: -20px;
                }
                &__list{
                    &__item{
                        margin-left: 30px;
                    }
                    &__contact{
                        margin-left: 200px;
                    }
                }
            }
        }
    }
}